import { render, staticRenderFns } from "./CommentManagement.vue?vue&type=template&id=a258a21e&scoped=true&"
import script from "./CommentManagement.vue?vue&type=script&lang=js&"
export * from "./CommentManagement.vue?vue&type=script&lang=js&"
import style0 from "./CommentManagement.vue?vue&type=style&index=0&id=a258a21e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a258a21e",
  null
  
)

export default component.exports